<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="col-lg-12 rowSpace"></div>
        <div class="text-center col-lg-12 mainTitle">챌린저 골프</div>
        <div class="mainTitleLine">
          <hr />
        </div>
        <div class="col-lg-12 text-center mainIntro">한국 최초의 세계 100대 골프장에서 경험하는 프리미엄 골프 레슨</div>
        <div class="rowSpace"></div>
        <div class="col buttonArea">
          <router-link to="/p/Pleisure" class="prev-button">이전 </router-link>
          <router-link to="/p/Reservpleisure/1" class="resserve-button"> 예약신청 </router-link>
        </div>
        <div class="rowSpace"><hr /></div>
        <GallerySlideCarousels :slides="main_slides" :slide-count="3" :thumb-slide-width="45" />
      </div>
      <div class="container subContainer">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-12 mainInfo">
            <!--            <div>핀크스골프클럽에서 진행되는 프리미엄 필드레슨 프로그램입니다.</div>
            <div>
              스윙체크 등의 기본과 트러블 샷 관리, 숏게임 스킬, 퍼팅 등의 특별한 레슨을 통해 개인의 골프실력을 향상시켜
              드립니다.
            </div>-->
            <div style="word-break: keep-all">
              한국 최초의 세계 100대 골프장 핀크스에서 경험하는 프리미엄 골프 레슨 프로그램.<br />
              스윙 체크 등의 기본과 트러블 샷 관리, 숏게임 스킬, 퍼팅 등의 특별한 레슨을 통해 개인의 골프 실력을
              향상시켜 드립니다.
            </div>
            <div>※ 연령제한 14세 이상</div>
          </div>
        </div>
      </div>
      <div class="rowSpace"><hr /></div>
      <div class="col-lg-12 serviceArea">
        <div class="subTitle">공통 의전 및 서비스</div>
        <div class="subDetail">
          <div>- 차량 의전 서비스 (벤츠 스프린터 / 카니발 하이리무진)</div>
          <div>- 핀크스 의전 담당 매니저 케어</div>
          <div>- 어메니티, 미니바 제공</div>
        </div>
      </div>
      <div class="rowSpace"><hr /></div>
      <div class="col-lg-12 serviceArea">
        <div class="subTitle">메인 프로그램</div>
        <div class="subDetail">
          · 장소 : 핀크스 골프클럽<br />
          · 프로그램 : 원포인트, 필드레슨, 레슨 전/후 개인 맞춤 프로그램
        </div>
        <div class="rowSpace"></div>
        <table class="col-lg-12 col-sm-3 table table-bordered subTable mobileTable">
          <thead>
            <tr>
              <td>옵션</td>
              <td>최대인원</td>
              <td>소요시간</td>
              <td colspan="2">요금(2인기준)</td>
              <td>인원 추가비</td>
              <td>비고</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="2">원포인트<br />+필드레슨</td>
              <td rowspan="4">3인<br />(1인 이용불가)</td>
              <td rowspan="2">5 ~ 5.5H</td>
              <td>월 ~ 금 1부</td>
              <td>1,900,000</td>
              <td>330,000</td>
              <td rowspan="4">※ 캐디피 별도</td>
            </tr>
            <tr>
              <td>금2부 ~ 일</td>
              <td>2,100,000</td>
              <td>400,000</td>
            </tr>
            <tr>
              <td rowspan="2">필드레슨</td>
              <td rowspan="2">4.5 ~ 5H</td>
              <td>월 ~ 금 1부</td>
              <td>1,700,000</td>
              <td>280,000</td>
            </tr>
            <tr>
              <td>금2부 ~ 일</td>
              <td>1,900,000</td>
              <td>350,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container">
      <div class="rowSpace"><hr /></div>
      <div class="row d-flex justify-content-center">
        <!--        <div class="col-lg-12 serviceArea">
          <div class="subTitle">[여름 한정]특별옵션</div>

          <div class="subTitle">※ 7월~8월, 챌린저 골프 고객 대상 그린사이드BBQ 특별 이벤트</div>

          <GallerySlideCarousels :slides="addition_slides" :slide-count="3" :thumb-slide-width="50" />

          <div class="rowSpace"></div>
          <div class="subDetail">
            7월~8월 여름 기간 동안 챌린저골프 고객 한정, 그린사이드BBQ를 특별가에 경험할 수 있는 프로그램입니다.<br />
            스타트 하우스 앞 푸른 잔디 위에서 즐기는 로맨틱 다이닝으로 추억 가득한 여름 휴가를 만들어보세요.
          </div>
          <div class="rowSpace"><hr /></div>

          <div class="subTitle">요금안내</div>
          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>옵션</td>
                <td>구성</td>
                <td>요금(2인 기준)</td>
                <td>인원 추가비</td>
                <td>비고</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>그린사이드BBQ<br />Special Course</td>
                <td>그린사이드BBQ특선코스(2인)<br />부르고뉴 와인(1BT/750ml)</td>
                <td>400,000</td>
                <td>150,000</td>
                <td>추가와인 별도</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rowSpace"><hr /></div>-->
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">추가옵션</div>

          <GallerySlideCarousels :slides="addition2_slides" :slide-count="3" :thumb-slide-width="50" />

          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>구분</td>
                <td colspan="2">옵션(2인기준)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>150,000</td>
                <td>수테라피, 사격, 오모로비짜 스파(40분)</td>
                <td rowspan="5">※ 3인 이상 시 전화 문의</td>
              </tr>
              <tr>
                <td>200,000</td>
                <td>메디컬 스파(바디)</td>
              </tr>
              <tr>
                <td>350,000</td>
                <td>오모로비짜 스파(90분)</td>
              </tr>
              <tr>
                <td>500,000</td>
                <td>스냅(요트)</td>
              </tr>
              <tr>
                <td>플라워 세팅</td>
                <td>300,000 / 500,000 / 700,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">기타사항</div>
          <div class="subDetail">
            <div>※ 5세 이상부터 1인 요금이 적용됩니다.</div>
            <div>※ 경우에 따라 미성년자의 경우에는 보호자 동반이 필요할 수 있습니다.</div>
            <div>※ 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라 적용됩니다.</div>
            <div>
              - 7일 전 위약금 0%, 6일 전 ~ 5일 전 30%, 4일 전 50%, 3일 전~2일 전(~17시) 70%, 2일전(17시~) ~ 당일 100%
              위약금 부과
            </div>
            <div>※ 천재지변 및 업체의 운영 상 부득이한 상황으로 취소 시, 위약 규정이 적용되지 않습니다.</div>
            <div>※ 여행자보험 포함 상품입니다.</div>
            <div>※ 요금표 외 기타 인원은 전화 문의 바랍니다.</div>
          </div>
        </div>
        <div class="rowSpace"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlideCarousels from '@/components/GallerySlideCarousels';

export default {
  name: 'challengergolf',
  components: {GallerySlideCarousels},
  data() {
    return {
      main_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage02.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage02-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage02-3.jpg')},
      ],
      addition_slides: [
        /*{image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage10.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},*/
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13-3.jpg')},
      ],
      addition2_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '챌린저골프'});
  },
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
  padding-top: 11rem;
}
.mainTitle {
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resserve-button {
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: #524a3d;
    color: #fff;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      text-align: center;
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      text-align: center;
      font-size: 0.9rem;
    }
  }
}

.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}

.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }

  .mobileTable {
    font-size: 1.1rem;
  }
}
</style>
